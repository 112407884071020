import Btn from '@/components/Btn/Btn.vue';

export default {
  name: 'PlansReportForm',
  components: { Btn },
  props: {
    loading: { type: Boolean, default: false },
    reportInfo: {
      type: Object,
      default: {
        name: null,
        plans: null,
      },
    },
  },
  data: () => ({
    nameRules: [v => !!v || 'Report Name is Required'],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate() && this.reportInfo.plans && this.reportInfo.plans.length > 0) {
        this.$emit('submit');
      }
    },
  },
};
