import Btn from '@/components/Btn/Btn.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import FileInputList from '@/components/FileInputList/FileInputList.vue';
import eventHub from '@/utils/eventHub';
import DELETE_DOCUMENT_MUTATION from '@/graphql/mutations/deleteDocument.gql';
import ADD_DOCUMENT_MUTATION from '@/graphql/mutations/addDocument.gql';
import UPDATE_DOCUMENT_MUTATION from '@/graphql/mutations/updateDocument.gql';
import GET_DOCUMENTS_QUERY from '@/graphql/queries/documents.gql';

export default {
  name: 'Documents',
  components: { Btn, FileInputList },
  props: {
    loading: { type: Boolean },
    client: { type: Object },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    fileList: [],
    internalLoading: false,
  }),
  watch: {
    client: {
      handler() {
        this.updateFileList();
      },
      immediate: true,
    },
  },
  computed: {
    isLoaded() {
      return !this.loading && !this.internalLoading;
    },
  },
  methods: {
    change({ value, index }) {
      if (this.client.id && value instanceof File && typeof index === 'number') {
        const variables = {
          clientId: this.client.id,
          documentUrl: '',
          documentFile: value,
          name: value.name,
        };
        let mutation = ADD_DOCUMENT_MUTATION;
        if (this.client.documents[index] && this.client.documents[index].id) {
          variables.id = this.client.documents[index].id;
          mutation = UPDATE_DOCUMENT_MUTATION;
        }
        this.uploadFile(variables, mutation, index);
      }
    },
    remove(index) {
      if (
        this.client.id &&
        typeof index === 'number' &&
        this.client.documents[index] &&
        this.client.documents[index].id
      ) {
        this.removeFile(this.client.documents[index].id);
      }
    },
    updateFileList() {
      this.fileList = [];
      this.client &&
        Array.isArray(this.client.documents) &&
        this.client.documents.forEach(item => {
          if (item) {
            if (item instanceof File) {
              this.fileList.push(item);
            } else if (item.name || item.documentUrl) {
              this.fileList.push(item.name || item.documentUrl);
            }
          }
        });
    },
    removeFile(id) {
      if (id) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: DELETE_DOCUMENT_MUTATION,
            variables: {
              id,
            },
          })
          .then(() => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was removed successfully!`,
            });
            const index = this.client.documents.findIndex(item => item.id === id);
            if (index > -1) {
              this.client.documents.splice(index, 1);
            }
          })
          .catch(e => {
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
    uploadFile(variables, mutation, index) {
      if (variables && mutation) {
        this.internalLoading = true;
        this.$apollo
          .mutate({
            mutation: mutation,
            variables: {
              input: variables,
            },
          })
          .then(({ data: { addDocument, updateDocument } }) => {
            eventHub.$emit('show-snackbar', {
              color: 'success',
              text: `File was uploaded successfully!`,
            });
            this.client.documents[index] = addDocument || updateDocument;
            this.$emit('submit', addDocument || updateDocument);
          })
          .catch(e => {
            eventHub.$emit('show-snackbar', {
              color: 'error',
              text: `Error upload file. Max size of the file is 10 MB bytes. Please provide another file.`,
            });
            this.fileList.splice(index, 1);
            this.error = e;
          })
          .finally(() => {
            this.internalLoading = false;
          });
      }
    },
    finalizeDocumentsList() {
      this.$apollo
        .mutate({
          mutation: GET_DOCUMENTS_QUERY,
          variables: {
            clientId: this.client.id,
          },
        })
        .then(({ data: { documents } }) => {
          this.client.documents = documents;
        })
        .catch(e => {
          this.error = e;
        });
    },
    onNextClick() {
      this.finalizeDocumentsList();
      this.$emit('done');
    },
    onBackClick() {
      this.finalizeDocumentsList();
      this.$emit('back');
    },
  },
};
