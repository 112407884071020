import Btn from '@/components/Btn/Btn.vue';
import NumberTextField from '@/components/NumberTextField/NumberTextField.vue';
import validateRules from '@/services/validateRules';

export default {
  name: 'FacilityForm',
  components: { Btn, NumberTextField },
  props: {
    btnLabel: { type: String, required: true },
    loading: { type: Boolean, default: false },
    facility: { type: Object },
  },
  data: () => ({
    ...validateRules,
    currentUser: null,
    nameRules: [v => !!v || 'Name is Required'],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    onSubmit() {
      if (this.validate()) {
        this.$emit('submit', this.facility);
      }
    },
  },
};
