var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"components-report",attrs:{"mutation":_vm.GENERATE_REPORTS,"variables":{
    input: {
      planId: _vm.plan ? _vm.plan.id : null,
      name: _vm.reportInfo.name,
      reports:
        _vm.reportInfo.reports &&
        _vm.reportInfo.reports.reduce(function (o, r) {
          o[r] = true;
          return o;
        }, {}),
      data: _vm.inputComponents,
    },
  },"update":_vm.downloadFile},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('v-overlay',{attrs:{"z-index":10000,"value":loading}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('p',[_vm._v("Generating report... Please do not refresh or close the tab.")])]),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1)],1),_c('components-report-form',_vm._b({on:{"submit":function($event){return mutate()}}},'components-report-form',{
        loading: loading,
        reportInfo: _vm.reportInfo,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }