var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({staticClass:"add-facility-form",attrs:{"mutation":_vm.ADD_FACILITY_MUTATION,"variables":{
    input: Object.assign({}, _vm.facility,
      {clientId: (_vm.client && _vm.client.id) || null}),
  },"update":_vm.updateFacilities},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var mutate = ref.mutate;
  var loading = ref.loading;
  var error = ref.error;
return [(!loading)?_c('alert',{attrs:{"align":"center","error":error}}):_vm._e(),_c('facility-form',_vm._b({on:{"submit":function($event){return mutate()}}},'facility-form',{
        btnLabel: 'Add',
        loading: loading,
        facility: _vm.facility,
      },false))]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }