var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"bottom":"","min-width":"150px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","text":""}},on),[_c('v-icon',[_vm._v("fa-ellipsis-v")])],1)]}}]),model:{value:(_vm.menuOpen),callback:function ($$v) {_vm.menuOpen=$$v},expression:"menuOpen"}},[_c('v-card',{staticClass:"actions-container"},[_c('v-list',{staticClass:"actions",attrs:{"dense":"","nav":""}},[_vm._l((_vm.actions),function(action,index){return [(!action.modal)?_c('v-list-item',{key:index,staticClass:"action",attrs:{"link":!!action.to,"to":action.to,"active-class":"action-active"},on:{"click":function($event){return _vm.onItemClick(action)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(action.name)+" ")])],1)],1):(action.modal.type === 'common')?_c('modal',_vm._b({key:index,on:{"close":_vm.onModalClose},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var modalOn = ref.on;
var attrsModal = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"action",attrs:{"active-class":"action-active"}},'v-list-item',attrsModal,false),modalOn),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.name))])],1)],1)]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c(action.modal.content.componentName,_vm._b({tag:"component",on:{"done":close}},'component',Object.assign({}, action.modal.content.props),false))]}}],null,true)},'modal',Object.assign({}, action.modal.props),false)):_c('confirmation-modal',_vm._b({key:index,on:{"close":_vm.onModalClose},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var modalOn = ref.on;
var attrsModal = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"action",attrs:{"active-class":"action-active"}},'v-list-item',attrsModal,false),modalOn),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(action.name))])],1)],1)]}}],null,true)},'confirmation-modal',Object.assign({}, action.modal.props),false))]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }