import Btn from '@/components/Btn/Btn.vue';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import DefinitionList from '@/components/DefinitionList/DefinitionList.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import PlanFinancialInfo from '@/components/PlanFinancialInfo/PlanFinancialInfo.vue';
import { calculateBudget } from '@/services/componentBudget';
import flaggedComponentMixin from '@/mixins/flaggedComponentMixin';

export default {
  name: 'PlanInfo',
  components: { Btn, ExpansionPanelHeader, DefinitionList, DataTable, PlanFinancialInfo },
  mixins: [flaggedComponentMixin],
  props: {
    plan: { type: Object, default: null },
    components: { type: Array, default: null },
    loading: { type: Boolean, default: false },
  },
  data: () => ({
    currentUser: null,
    expanded: 0,
    error: null,
    generalInfoLabels: [
      {
        label: 'Plan Name',
        value: 'name',
      },
      {
        label: 'ICS Project Number',
        value: 'icsProjectNumber',
      },
      {
        label: 'Date of Plan Creation',
        value: 'dateOfCreation',
        type: 'utcDate',
      },
      {
        label: 'Date of Assessment',
        value: 'dateOfAssessment',
        type: 'utcDate',
      },
      {
        label: 'Link to Plan Photos',
        value: 'photosLink',
        type: 'link',
      },
      {
        label: 'Plan Notes',
        value: 'description',
        type: 'longstring',
      },
    ],
  }),
  computed: {
    displayedItems() {
      return this.plan && this.components
        ? this.components.map(component => {
            const budget = calculateBudget(component, this.plan);
            return {
              ...component,
              facilityName: (component.facility && component.facility.name) || '',
              systemName: (component.system && component.system.name) || '',
              componentName: (component.component && component.component.name) || '',
              unitName: (component.unit && component.unit.name) || '',
              conditionName: (component.conditionAssessment && component.conditionAssessment.name) || '',
              fundingSourceName: (component.fundingSource && component.fundingSource.name) || '',
              ...budget,
            };
          })
        : [];
    },
    headers() {
      return [
        {
          text: 'Flag',
          value: 'flag',
          dataType: 'string',
          width: '40px',
          fixed: true,
          type: 'slot',
        },
        {
          text: 'Facility',
          value: 'facilityName',
          type: 'select',
          dataType: 'string',
          fixed: true,
          width: '150px',
        },
        { text: 'System', value: 'systemName', type: 'select', dataType: 'string', width: '180px' },
        {
          text: 'Component',
          value: 'componentName',
          type: 'select',
          dataType: 'string',
          width: '200px',
        },
        { text: 'Year', value: 'yearForImprovement', type: 'select', dataType: 'year', width: '100px' },
        { text: 'Quantity', value: 'quantityOfComponents', type: 'number', dataType: 'number', width: '80px' },
        { text: 'Unit', value: 'unitName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Cost Per Unit', value: 'costPerUnit', type: 'number', dataType: 'currency', width: '100px' },
        { text: 'Base Amount', value: 'baseAmount', type: 'number', dataType: 'currency', width: '100px' },
        {
          text: 'Component Subtotal',
          value: 'componentSubtotal',
          type: 'number',
          dataType: 'currency',
          width: '100px',
        },
        { text: 'Soft Cost', value: 'softCost', width: '70px' },
        { text: 'Budget Amount', value: 'budgetAmount', type: 'number', dataType: 'currency', width: '120px' },
        { text: 'Budgetary Notes', value: 'budgetaryNotes', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Description', value: 'description', type: 'text', dataType: 'longstring', width: '180px' },
        { text: 'Condition', value: 'conditionName', type: 'select', dataType: 'string', width: '100px', noSort: true },
        { text: 'Location', value: 'locationOfComponent', type: 'text', dataType: 'longstring', width: '130px' },
        { text: 'Image', value: 'componentUrl', width: '100px' },
        { text: 'Funding Source', value: 'fundingSourceName', type: 'select', dataType: 'string', width: '100px' },
        { text: 'Internal Notes', value: 'internalNotes', type: 'text', dataType: 'longstring', width: '180px' },
      ];
    },
  },
};
