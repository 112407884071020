import GENERATE_REPORT from '@/graphql/mutations/generateAllClientsReport.gql';
import eventHub from '@/utils/eventHub';
import PlansReportForm from './PlansReportForm/PlansReportForm.vue';
import { download } from '@/utils/file';

export default {
  name: 'PlansReport',
  components: { PlansReportForm },
  props: {
    plans: {
      type: Array,
      default: null,
    },
  },
  data: vm => ({
    GENERATE_REPORT,
    reportInfo: {
      name: null,
      plans: vm.plans,
    },
    inputPlans: vm.getInputPlans(vm.plans),
  }),
  watch: {
    plans: {
      immediate: true,
      handler(value) {
        if (value !== null) {
          this.inputPlans = this.getInputPlans(value);
        }
      },
      deep: true,
    },
  },
  methods: {
    downloadFile(_, { data: { generateAllClientsReport } }) {
      if (generateAllClientsReport) {
        download(generateAllClientsReport.url, this.name);
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `Report was generated successfully!`,
        });
        this.$emit('done');
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
    getInputPlans(plans) {
      return plans
        ? plans.map(p => {
            return p.id;
          })
        : undefined;
    },
  },
};
