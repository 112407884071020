import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    themes: {
      light: {
        primary: '#2e4768',
        secondary: '#1564b7',
        accent: '#F5B14D',
        accent1: '#F1CE9A',
        accent2: '#F6DDB9',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#fff',
        menu: '#2e4768',
        'ics-light-grey-blue': '#f0f2f4',
        'ics-light-grey': '#C8C8C8',
        'ics-grey': '#8C8C8C',
      },
      dark: {
        primary: '#c9d3e1',
        secondary: '#1564b7',
        accent: '#F5B14D',
        accent1: '#51739f',
        accent2: '#4a6a93',
        error: '#ff8181',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#4b5562',
        menu: '#2e4768',
        anchor: '#9ebbe3',
        'ics-light-grey-blue': '#5f6a78',
        'ics-light-grey': '#3c4046',
        'ics-grey': '#b0b3b7',
      },
    },
    options: { customProperties: true, variations: false },
  },
});
