import Vue from 'vue';
import { VTextField } from 'vuetify/lib';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VTextField] });
export default base.extend({
  name: 'NumberTextField',
  components: {
    VTextField,
  },
  methods: {
    onChange(val) {
      const newVal = val !== '' ? val : null;
      this.$emit('input', newVal);
      this.$emit('change', newVal);
    },
  },
});
