import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import ComponentList from './ComponentList/ComponentList.vue';
import BudgetTable from './BudgetTable/BudgetTable.vue';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import COMPONENTS_OF_NEEDS from '@/graphql/queries/componentsOfNeed.gql';
import UPDATE_COMPONENTS_OF_NEEDS from '@/graphql/mutations/updateComponentsOfNeed.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import { COMPONENT_STATUS } from '@/enums/componentStatus';
import { isEqual, cloneDeep } from 'lodash';
import ExpansionPanelHeader from '@/components/ExpansionPanelHeader/ExpansionPanelHeader.vue';
import ComponentsReport from '@/components/ComponentsReport/ComponentsReport.vue';
import DataTableColumns from '@/components/DataTableColumns/DataTableColumns.vue';
import PlanFinancialInfo from '@/components/PlanFinancialInfo/PlanFinancialInfo.vue';
import BudgetCalculationInfo from '../BudgetCalculationInfo/BudgetCalculationInfo.vue';
import { generateRangeArray } from '@/utils/generator';

const TABLE_UID = 'PLANING_BUDGET_COMPONENTS';

export default {
  name: 'BudgetPlanning',
  components: {
    Btn,
    BudgetCalculationInfo,
    Modal,
    ComponentList,
    ExpansionPanelHeader,
    BudgetTable,
    ComponentsReport,
    DataTableColumns,
    PlanFinancialInfo,
  },
  props: {
    plan: {
      type: Object,
    },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    componentsOfNeed: {
      query: COMPONENTS_OF_NEEDS,
      variables() {
        return {
          planId: (this.plan && this.plan.id) || null,
          status: COMPONENT_STATUS.OPEN,
        };
      },
      skip() {
        return this.plan === null || !this.plan.id;
      },
      error(error) {
        this.error = error.networkError
          ? { ...error, message: 'Something went wrong! Could not load plan components.' }
          : error;
      },
    },
  },
  data: () => ({
    TABLE_UID,
    currentUser: null,
    componentsOfNeed: null,
    error: null,
    internalLoading: false,
    components: [],
    changes: [],
    selected: [],
    filtered: [],
    expanded: [],
    headers: [],
    selectedHeaders: null,
    selectedYear: null,
  }),
  watch: {
    componentsOfNeed(value) {
      if (value) {
        this.components = cloneDeep(value);
        this.changes = [];
        this.selected = [];
        this.filtered = [];
      }
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.componentsOfNeed.loading;
    },
    showGenerateReportBtn() {
      return accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser);
    },
    canEdit() {
      return accessByPermissions(PERMISSIONS.EDIT_PLAN_COMPONENT, this.currentUser);
    },
    reportComponents() {
      const fullListWithChanges = this.components.map(c => {
        const changed = this.changes.find(ch => c.id === ch.id);
        return changed ? changed : c;
      });
      return this.selected.length > 0 ? this.selected : this.filtered.length > 0 ? this.filtered : fullListWithChanges;
    },
    showBulkYearChangeBtn() {
      return this.filtered.length > 0;
    },
    years() {
      if (this.plan !== null && this.plan.financialInfo !== null) {
        if (this.plan.financialInfo.planStartYear !== null) {
          const year = parseInt(this.plan && this.plan.financialInfo && this.plan.financialInfo.planStartYear);

          return generateRangeArray(year, year + 19);
        }
      }

      return generateRangeArray(new Date().getFullYear(), new Date().getFullYear() + 19);
    },
  },
  methods: {
    onModalClose() {
      this.changes = [];
      this.selected = [];
      this.filtered = [];
      this.onReset();
      this.components = cloneDeep(this.componentsOfNeed);
    },
    onReset() {
      this.selected = [];
    },
    onSelect(items) {
      this.selected = items;
    },
    onFilter(items) {
      this.filtered = items;
    },
    onChange(item, key) {
      const component = this.componentsOfNeed.find(c => item.id === c.id);
      const index = this.changes.findIndex(c => c.id === item.id);
      if (component && component[key] !== item[key]) {
        if (index >= 0) {
          this.changes[index] = item;
        } else {
          this.changes.push(item);
        }
      } else if (component && index >= 0) {
        const hasChanges = Object.entries(component).some(([key]) => {
          return !isEqual(component[key], item[key]);
        });
        if (!hasChanges) {
          this.changes = this.changes.filter(c => c.id !== item.id);
        }
      }
    },
    onApplyToPlan(close) {
      this.internalLoading = true;
      const input = this.changes.map(item => ({
        id: item.id,
        facilityId: (item.facility && item.facility.id) || null,
        systemId: (item.system && item.system.id) || null,
        componentId: (item.component && item.component.id) || null,
        status: item.status,
        yearCompleted: item.yearCompleted,
        actualCost: item.actualCost,
        description: item.description,
        componentUrl: item.componentUrl,
        yearForImprovement: item.yearForImprovement,
        quantityOfComponents: item.quantityOfComponents,
        unitId: (item.unit && item.unit.id) || null,
        costPerUnit: item.costPerUnit,
        conditionAssessmentId: (item.conditionAssessment && item.conditionAssessment.id) || null,
        locationOfComponent: item.locationOfComponent,
        fundingSourceId: (item.fundingSource && item.fundingSource.id) || null,
        budgetaryNotes: item.budgetaryNotes,
        internalNotes: item.internalNotes,
        componentNotes: item.componentNotes,
        softCost: item.softCost,
        flag: item.flag,
      }));
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPONENTS_OF_NEEDS,
          variables: {
            input: input,
          },
          update: (store, { data: { updateComponentsOfNeed } }) => {
            // Read the data from our cache for this query.
            const data = store.readQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: { planId: (this.plan && this.plan.id) || null, status: COMPONENT_STATUS.OPEN },
            });
            updateComponentsOfNeed.forEach(updated => {
              const index = data.componentsOfNeed.findIndex(c => c.id === updated.id);
              if (index >= 0) {
                data.componentsOfNeed[index] = updated;
              }
            });
            store.writeQuery({
              query: COMPONENTS_OF_NEEDS,
              variables: { planId: (this.plan && this.plan.id) || null, status: COMPONENT_STATUS.OPEN },
              data,
            });
          },
        })
        .then(() => {
          // close modal
          if (close) {
            close();
          }
        })
        .catch(error => {
          this.error = error;
          if (close) {
            close();
          }
        })
        .finally(() => {
          this.internalLoading = false;
        });
    },
    onInitHeaders(value) {
      this.headers = value;
    },
    confirmBudgetPlanning() {
      if (this.changes.length && this.$refs.confirm_modal) {
        this.$refs.confirm_modal.modalOpen = true;
      } else {
        this.closeBudgetPopup();
      }
    },
    closeBudgetPopup() {
      this.$refs.main_modal && (this.$refs.main_modal.modalOpen = false);
    },

    bulkUpdateComponentsYear(year) {
      this.$refs.component_list.massChage('yearForImprovement', year);
      this.$refs.component_list.setFilterValue('yearForImprovement', [year]);
    },
  },
};
