import Vue from 'vue';
function currencyFilter(value) {
  if (value === null || value === undefined || value === '') return '';
  return value === '--'
    ? value
    : `$ ${new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(Number(value))}`;
}

Vue.filter('currency', currencyFilter);

export default currencyFilter;
