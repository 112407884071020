import Vue from 'vue';
import { VDialog } from 'vuetify/lib';
import Btn from '@/components/Btn/Btn.vue';
// Create Base Mixins and Define Custom Properties
const base = Vue.extend({ mixins: [VDialog] });
export default base.extend({
  name: 'Modal',
  components: {
    VDialog,
    Btn,
  },
  props: {
    title: { type: String },
    overlayColor: {
      type: String,
      default: 'black',
    },
    overlayOpacity: {
      type: String,
      default: '.8',
    },
    noClose: false,
    closeFunction: Function,
  },
  data: () => ({
    modalOpen: false,
  }),
  watch: {
    modalOpen(value) {
      if (!value) {
        this.$emit('close');
      }
    },
  },
  methods: {
    close(event) {
      if (typeof this.closeFunction === 'function') {
        this.closeFunction(event);
      } else {
        this.modalOpen = false;
      }
    },
  },
});
