var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{staticClass:"plans-container",attrs:{"query":_vm.PLANS_QUERY},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
return [_c('alert',{attrs:{"error":error}}),_c('plan-list',{attrs:{"plans":(data && data.plans) || [],"loading":loading,"selected":_vm.selected,"show-select":_vm.showGenerateReportBtn},on:{"select":_vm.onSelect}}),_c('v-row',{staticClass:"mt-6",attrs:{"no-gutters":"","justify":"end"}},[_c('btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableReportBtn),expression:"!disableReportBtn"}],attrs:{"width":"150","color-type":"accent","text":"","disabled":loading},on:{"click":_vm.onReset}},[_vm._v("Reset Included")]),(_vm.showGenerateReportBtn)?_c('modal',{attrs:{"title":"Generate Report","width":"440px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"width":"200","color-type":"accent","disabled":loading || _vm.disableReportBtn}},'btn',attrs,false),on),[_vm._v("Generate Report")])]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('plans-report',{attrs:{"plans":_vm.selected},on:{"done":close}})]}}],null,true)}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }