import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import PLANS_QUERY from '@/graphql/queries/plans.gql';
import DELETE_PLAN_MUTATION from '@/graphql/mutations/deletePlan.gql';
import eventHub from '@/utils/eventHub';
import ComponentsReport from '@/components/ComponentsReport/ComponentsReport.vue';
import ClonePlanModal from '@/components/Plans/PlanList/ClonePlanModal/ClonePlanModal.vue';

export default {
  name: 'PlanList',
  components: { Btn, DataTable, ConfirmationModal, ActionsMenu },
  props: {
    plans: { type: Array, default: [] },
    loading: { type: Boolean },
    selected: { type: Array },
    showSelect: { type: Boolean, default: false },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    DELETE_PLAN_MUTATION,
    currentUser: null,
    error: null,
    deleteConfirmationModal: false,
    headers: [
      {
        text: 'Name',
        value: 'name',
        type: 'text',
        dataType: 'string',
        width: '150px',
        fixed: true,
      },
      {
        text: 'Date of Creation',
        value: 'dateOfCreation',
        type: 'date',
        dataType: 'date',
        width: '150px',
      },
      {
        text: 'Client',
        value: 'clientName',
        type: 'select',
        dataType: 'string',
        width: '150px',
      },
      { text: 'ICS Responsible Office', value: 'icsOfficeName', type: 'select', dataType: 'string', width: '150px' },
      { text: 'Client Type', value: 'clientTypeName', type: 'select', dataType: 'string', width: '150px' },
      { text: 'Plan Type', value: 'planType', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Creator', value: 'creatorName', type: 'select', dataType: 'string', width: '120px' },
      { text: 'Editor', value: 'editorName', type: 'select', dataType: 'string', width: '150px' },
      { text: 'Last Updated', value: 'updatedAt', type: 'date', dataType: 'date', width: '180px' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '40px',
        fixed: true,
        permissions: [
          PERMISSIONS.EDIT_PLAN,
          PERMISSIONS.CLONE_PLAN,
          PERMISSIONS.GENERATE_REPORT,
          PERMISSIONS.DELETE_PLAN,
        ],
      },
    ],
  }),
  computed: {
    displayedItems() {
      return this.plans
        ? this.plans.map(plan => {
            return {
              ...plan,
              clientName: (plan.client && plan.client.name) || '',
              creatorName: (plan.createdBy && plan.createdBy.username) || '',
              icsOfficeName: (plan.client && plan.client.icsOffice && plan.client.icsOffice.name) || '',
              clientTypeName: (plan.client && plan.client.clientType && plan.client.clientType.name) || '',
              planType:
                (plan.financialInfo && plan.financialInfo.typeOfPlan && plan.financialInfo.typeOfPlan.name) || '',
              editorName: (plan.editedBy && plan.editedBy.username) || '',
              canViewDetails: accessByPermissions(PERMISSIONS.VIEW_PLAN, this.currentUser),
              actions: [
                accessByPermissions(PERMISSIONS.GENERATE_REPORT, this.currentUser) && {
                  name: 'Generate Report',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Generate Report',
                      width: '380px',
                    },
                    content: {
                      componentName: ComponentsReport,
                      props: {
                        plan: plan,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.EDIT_PLAN, this.currentUser) && {
                  name: 'Edit',
                  to: { name: 'plan', params: { planId: plan.id } },
                },
                accessByPermissions(PERMISSIONS.CLONE_PLAN, this.currentUser) && {
                  name: 'Clone',
                  modal: {
                    type: 'common',
                    props: {
                      title: 'Clone Plan',
                      width: '380px',
                    },
                    content: {
                      componentName: ClonePlanModal,
                      props: {
                        plan: plan,
                      },
                    },
                  },
                },
                accessByPermissions(PERMISSIONS.DELETE_PLAN, this.currentUser) && {
                  name: 'Delete',
                  modal: {
                    props: {
                      mutation: DELETE_PLAN_MUTATION,
                      variables: { id: plan.id },
                      title: 'Delete Plan',
                      message: 'Are you sure you want to delete plan?',
                      okBtn: { label: 'Delete' },
                      update: this.onDelete(plan),
                    },
                  },
                },
              ],
            };
          })
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(
        h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser))
      );
    },
    selectedState: {
      get() {
        return this.selected;
      },
      set(localState) {
        this.$emit('select', localState);
      },
    },
  },
  methods: {
    onDelete(item) {
      return (store, { data: { deletePlan } }) => {
        if (deletePlan) {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: PLANS_QUERY,
          });
          data.plans = data.plans.filter(plan => {
            return plan.id !== item.id;
          });
          store.writeQuery({
            query: PLANS_QUERY,
            data,
          });
          eventHub.$emit('show-snackbar', {
            color: 'success',
            text: 'Plan was deleted from the system successfully!',
          });
        }
      };
    },
  },
};
